import { gql } from 'urql/core';

export default gql`
  mutation updateMedication($input: UpdateMedicationInput!) {
    updateMedication(input: $input) {
      id
      name
      pillAppearance
      pillAppearanceThumb
      medicationType {
        id
        name
      }
      color
      color2
      pillDescription
      instructions
      otherInfo
    }
  }
`;
