import { gql } from 'urql/core';

export default gql`
query getMedication($id: Int!) {
  medication(id: $id) {
    id
    name
    pillAppearance
    pillAppearanceThumb
    medicationType {
      id
      name
    }
    color
    color2
    pillDescription
    instructions
    otherInfo
  }
}
`;
