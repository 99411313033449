<script>
import { reactive, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import FormInput from '@/components/form/FormInput.vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import { useMutation, useQuery } from '@urql/vue';
import Button from '@/components/Button.vue';
import UpdateMedicationMutation from '@/queries/UpdateMedication';
import GetMedicationTypes from '@/queries/GetMedicationTypes';
import MedicationTypeCreate from '@/modals/MedicationTypeCreate.vue';
import GetMedication from '@/queries/GetMedication';

export default {
  components: {
    FormInput,
    Multiselect,
    Button,
    MedicationTypeCreate,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    const router = useRouter();
    const model = reactive({
      name: '',
      pillAppearance: null, // file
      medicationType: '', // id of med type
      color: '',
      color2: '',
      pillDescription: '',
      instructions: '',
      otherInfo: '',
      globalError: '',
      typeModalOpen: false,
    });

    const errors = reactive({
      name: false,
      pillAppearance: false, // file
      medicationType: false, // id of med type
      color: false,
      color2: false,
      pillDescription: false,
      instructions: false,
      otherInfo: false,
    });

    const medicationTypesResult = useQuery({
      query: GetMedicationTypes,
    });

    const medTypes = computed(() => {
      if (medicationTypesResult.data.value && medicationTypesResult.data.value.medicationTypes) {
        return medicationTypesResult.data.value.medicationTypes;
      }
      return [];
    });

    if (props.id === null) {
      router.push({ name: 'admin-medications' }, () => {});
    }

    onMounted(async () => {
      const medicationResult = await useQuery({
        query: GetMedication,
        variables: {
          id: parseInt(props.id, 10),
        },
      });

      if (medicationResult.data.value.medication) {
        model.name = medicationResult.data.value.medication.name;
        model.medicationType = medicationResult.data.value.medication.medicationType;
        model.color = medicationResult.data.value.medication.color;
        model.color2 = medicationResult.data.value.medication.color2;
        model.pillDescription = medicationResult.data.value.medication.pillDescription;
        model.instructions = medicationResult.data.value.medication.instructions;
        model.otherInfo = medicationResult.data.value.medication.otherInfo;
      }
    });

    const onImageChange = (event) => {
      model.pillAppearance = event.target.files;
    };

    const resetErrors = () => {
      model.globalError = '';
      Object.keys(errors).forEach((key) => {
        errors[key] = false;
      });
    };

    const checkErrors = () => {
      resetErrors();
      let hasErrors = false;
      if (model.name.length <= 0) {
        errors.name = true;
        hasErrors = true;
      }
      if (!model.medicationType || model.medicationType.length <= 0) {
        errors.medicationType = true;
        hasErrors = true;
      }
      /* if (model.color.length <= 0) {
        errors.color = true;
        hasErrors = true;
      }
      if (model.color2.length <= 0) {
        errors.color2 = true;
        hasErrors = true;
      } */
      if (model.pillDescription.length <= 0) {
        errors.pillDescription = true;
        hasErrors = true;
      }
      return hasErrors;
    };

    const onMedicationTypeCreated = async (newMedType) => {
      await medicationTypesResult.executeQuery({
        requestPolicy: 'network-only',
      });
      model.medicationType = newMedType;
    };

    const { executeMutation: updateMed } = useMutation(UpdateMedicationMutation);

    const saveMed = () => {
      const hasErrors = checkErrors();

      if (hasErrors) {
        return;
      }

      const input = {
        id: parseInt(props.id, 10),
        name: model.name,
        medicationTypeId: model.medicationType.id,
        color: model.color,
        color2: model.color2,
        pillDescription: model.pillDescription,
        instructions: model.instructions,
        otherInfo: model.otherInfo,
      };

      if (model.pillAppearance && model.pillAppearance.length > 0) {
        [input.pillAppearance] = model.pillAppearance;
      }

      updateMed({ input })
        .then((result) => {
          if (result.errors) {
            model.globalError = 'Something happened.';
            // do something about it...
          }
          router.push({ name: 'admin-medications' }, () => {});
        });
    };

    return {
      model,
      medTypes,
      onImageChange,
      saveMed,
      errors,
      onMedicationTypeCreated,
    };
  },
};
</script>
<template>
  <div class="treatment-plan-form container">
    <MedicationTypeCreate
      v-if="model.typeModalOpen"
      @close="model.typeModalOpen = false"
      @created="onMedicationTypeCreated"
    />
    <div class="header">
      <h1>Edit {{model.name}}</h1>
    </div>
    <div
      class="error-banner"
      v-if="model.globalError.length > 0"
    >
      {{model.globalError}}
    </div>
    <div class="form">
      <div class="form-input">
        <FormInput
          label="Medication Name"
          v-model="model.name"
          :error="errors.name"
        />
      </div>
      <div class="form-input">
        <label>Medication Type</label>
        <div class="split-button">
        <Multiselect
          :options="medTypes"
          v-model="model.medicationType"
          :customLabel="(item) => item.name"
          trackBy="id"
        />
        <Button
          @click="model.typeModalOpen = true"
          size="full"
          type="secondary"
        >
          +
        </Button>
        </div>
        <span
          class="error"
          v-if="errors.medicationType"
        >
          This field is required.
        </span>
      </div>
      <div class="form-input">
        <FormInput
          label="Medication Image"
          type="file"
          @fileChange="onImageChange"
          :error="errors.pillAppearance"
        />
      </div>
      <div class="form-input two-col">
        <FormInput
          label="Pill Description"
          type="textarea"
          v-model="model.pillDescription"
          :error="errors.pillDescription"
          :maxLength="125"
        />
      </div>
      <div class="form-input two-col">
        <FormInput
          label="Instructions"
          type="textarea"
          v-model="model.instructions"
          :error="errors.instructions"
          :maxLength="125"
        />
      </div>
      <div class="form-input two-col">
        <FormInput
          label="Other Information"
          type="textarea"
          v-model="model.otherInfo"
          :error="errors.otherInfo"
          :maxLength="125"
        />
      </div>
    </div>
    <div class="footer">
      <Button @click="saveMed">
        Save
      </Button>
    </div>
  </div>
</template>
<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>
<style lang="scss">
  .multiselect__tags {
    height: 42px;
  }
  .multiselect__single {
    margin-top: 2px;
  }
  .multiselect__option--highlight {
    background: $BRAND_PRIMARY !important;
    &:after {
      background: $BRAND_PRIMARY !important;
    }
  }
</style>
<style lang="scss" scoped>
  .split-button {
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-gap: 1rem;
  }

  .error-banner {
    background: rgba($ERROR_COLOR, .2);
    border-left: 5px solid $ERROR_COLOR;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    color: $ERROR_COLOR;
  }

  .container {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 1rem;
    padding-top: 4rem;
  }
  h1 {
    @include header;
    text-align: left;
    padding: 1rem 0;
    font-size: 1.5rem;
    flex: 1;
  }

  .error {
    color: $ERROR_COLOR;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  .two-col {
    grid-column: 1 / 3;
    @include tablet {
      grid-column: 1;
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
  }
</style>
